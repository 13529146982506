<template>
<section class="bg-dark bg-size-cover bg-repeat-0 bg-position-center position-relative overflow-hidden py-5 mb-4"
  style="background-image: url(assets/img/landing/saas-3/hero/hero-bg.jpg);" data-bs-theme="dark">
  <div class="container position-relative zindex-2 pt-5 pb-md-2 pb-lg-4 pb-xl-5">
    <div class="row pt-3 pb-2 py-md-4">

      <!-- Texte -->
      <div class="col-xl-5 col-md-6 pt-lg-5 text-center text-md-start mb-4 mb-md-0">
        <h1 class="display-3 pb-2 pb-sm-3">Facilitez-vous la vie avec Throwbit</h1>
        <p class="text-body fs-lg d-md-none d-xl-block pb-2 pb-md-0 mb-4 mb-md-5">Échangez vos cryptomonnaies en toute sécurité avec Thowbit, et découvrez une nouvelle façon de gérer vos actifs numériques.</p>
        <div class="d-flex justify-content-center justify-content-md-start pb-2 pt-lg-2 pt-xl-0">
          <a href="/signup" class="btn btn-lg btn-primary shadow-primary me-3 me-sm-4">Commencer </a>
          <a href="/login" class="btn btn-lg btn-outline-secondary">Connexion</a>
        </div>
        <div class="d-flex align-items-center justify-content-center justify-content-md-start text-start pt-4 pt-lg-5 mt-xxl-5">
          <div class="d-flex me-3">
            <div class="d-flex align-items-center justify-content-center bg-light rounded-circle"
              style="width: 52px; height: 52px;">
              <img src="assets/img/avatar/14.jpg" class="rounded-circle" width="48" alt="Avatar">
            </div>
            <div class="d-flex align-items-center justify-content-center bg-light rounded-circle ms-n3"
              style="width: 52px; height: 52px;">
              <img src="assets/img/avatar/08.jpg" class="rounded-circle" width="48" alt="Avatar">
            </div>
            <div class="d-flex align-items-center justify-content-center bg-light rounded-circle ms-n3"
              style="width: 52px; height: 52px;">
              <img src="assets/img/avatar/15.jpg" class="rounded-circle" width="48" alt="Avatar">
            </div>
          </div>
          <div class="text-light"><strong>400k+</strong> utilisateurs nous font déjà confiance</div>
        </div>
      </div>

      <!-- Effets Parallax -->
      <div class="col-xl-7 col-md-6 d-md-flex justify-content-end">
        <div class="parallax mx-auto ms-md-0 me-md-n5" style="max-width: 675px;">
          <div class="parallax-layer zindex-2" data-depth="0.1">
            <img src="assets/img/logo_TB_COULEUR-03.png" alt="Card">
          </div>
          <div class="parallax-layer zindex-3" data-depth="0.25">
            <img src="assets/img/landing/saas-3/hero/layer02.png" alt="Bubble">
          </div>
          <div class="parallax-layer" data-depth="-0.15">
            <img src="assets/img/landing/saas-3/hero/layer03.png" alt="Bubble">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  </template>
  
  <script>
    // Ajoutez ici vos scripts si nécessaire
  </script>
  