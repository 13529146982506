<template>
  <header class="header navbar navbar-expand-lg navbar-dark fixed-top sticky-top header-custom">
  <div class="container px-3">
    <a href="/" class="navbar-brand pe-3">
      <img src="assets/img/throwbit_logo.png" width="130" alt="Silicon">
    </a>

    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Offcanvas menu -->
    <div id="navbarNav" class="offcanvas offcanvas-end bg-dark">
      <div class="offcanvas-header border-bottom border-light">
        <h5 class="offcanvas-title text-white">Menu</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a href="/" class="nav-link">Accueil</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">P2P Trade</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">À Propos</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">Blog</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">Contact</a>
          </li>
        </ul>
      </div>
      <div class="offcanvas-header border-top border-light">
        <a href="/" class="btn btn-primary w-100" target="_blank" rel="noopener">
          <i class="bx bx-cart fs-4 lh-1 me-1"></i>
          &nbsp;Tradez Maintenant
        </a>
      </div>
    </div>

    <!-- Trade now button (desktop) -->
    <a href="/" class="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex" target="_blank" rel="noopener">
      <i class="bx bx-cart fs-5 lh-1 me-1"></i>
      &nbsp;Tradez Maintenant
    </a>
  </div>
</header>
</template>

<script>

</script>
<style>
.header-custom {
  background-color: black;
}
</style>


